import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Share.module.scss'

const Share = ({ socialNetworks, thumb }) => {
  const currentURL = typeof window !== 'undefined' ? window.location.href : ''
  const encodedURL = encodeURIComponent(currentURL)
  const encodedTitle = encodeURIComponent('Share Title')
  const encodedThumbURL = thumb ? encodeURIComponent(thumb) : ''

  const handleClick = (event) => {
    const url = event.target.href
    window.open(url, 'popup', 'height=300,width=500')
  }

  return (
    <div className={styles.Share}>
      <div>Share on:</div>
      <ul className={styles.list}>
        {socialNetworks.map((item) => {
          let label
          let href
          let onClick = handleClick

          if (item === 'facebook') {
            label = 'Facebook'
            href = `http://facebook.com/sharer/sharer.php?u=${encodedURL}`
          } else if (item === 'twitter') {
            label = 'Twitter'
            href = `https://twitter.com/share?url=${encodedURL}&text=${encodedTitle}`
          } else if (item === 'linkedin') {
            label = 'LinkedIn'
            href = `http://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}`
          } else if (item === 'pinterest' && encodedThumbURL) {
            label = 'Pinterest'
            href = `https://pinterest.com/pin/create/button/?url=${encodedURL}&media=${encodedThumbURL}&description=${encodedTitle}`
          } else if (item === 'email') {
            label = 'Email'
            href = `mailto:?subject=${encodedTitle}&amp;body=${encodedURL}`
            onClick = null
          } else {
            return true
          }

          return (
            <li className={styles.list__item} key={item}>
              <a target="popup" href={href} onClick={onClick}>
                {label}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Share.defaultProps = {
  socialNetworks: ['facebook', 'linkedin', 'twitter', 'pinterest', 'email'],
  thumb: '',
}

Share.propTypes = {
  socialNetworks: PropTypes.arrayOf(PropTypes.string),
  thumb: PropTypes.string,
}

export { Share }
