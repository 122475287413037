import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getSlug } from '@/utils/getSlug'
import { getRichText } from '@/prismic/helpers/getRichText'
import { usePreviewData } from '@/prismic/hooks/usePreviewData'
import { getImage } from '@/prismic/helpers/getImage'
import BlogPost from '@/templates/BlogPost'

const BlogPostPrismic = ({ data }) => {
  const { prismicBlogPost } = usePreviewData(data)
  const title = prismicBlogPost.data?.title
  const imageLayout = prismicBlogPost.data.image_layout
    ? getSlug(prismicBlogPost.data.image_layout)
    : 'auto'

  return (
    <BlogPost
      title={title}
      content={getRichText(prismicBlogPost.data?.content?.raw)}
      image={getImage(prismicBlogPost.data?.image)}
      imageCaption={getRichText(prismicBlogPost.data?.image_caption?.raw)}
      date={prismicBlogPost.data.date}
      imageLayout={imageLayout}
    />
  )
}

BlogPostPrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPostPrismic

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      uid
      type
      data {
        title
        content {
          raw
        }
        image_caption {
          raw
        }
        date(formatString: "DD MMM YYYY", locale: "en-GB")
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          url
          alt
          dimensions {
            width
            height
          }
        }
        image_layout
      }
    }
  }
`
