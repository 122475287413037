import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { ImageCredit } from '@/components/ImageCredit'
import { RichText } from '@/components/RichText'
import { Container } from '@/components/Container'
import { Img } from '@/components/Img'
import { Share } from '@/components/Share'
import * as styles from './BlogPost.module.scss'

const BlogPost = ({
  title,
  date,
  content,
  image,
  imageLayout,
  imageCaption,
}) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const hasImage = image?.src && image?.width && image?.height
  let layout = 'portrait'

  if (!isMobile && hasImage) {
    if (imageLayout === 'auto') {
      if (image.width > 1200 && image.width / image.height > 1.2) {
        layout = 'landscape'
      } else {
        layout = 'portrait'
      }
    } else {
      layout = imageLayout
    }
  }

  const renderCaption = imageCaption ? (
    <div className={styles.caption}>
      <ImageCredit>{imageCaption}</ImageCredit>
    </div>
  ) : null

  const renderHeader = (
    <div className={styles.header}>
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.date}>{date}</div>
    </div>
  )

  return (
    <div
      className={classNames(styles.BlogPost, {
        [styles[`${layout}Layout`]]: !!layout,
      })}
    >
      <div className={styles.inner}>
        {layout === 'landscape' && (
          <Container>
            <div className={styles.featureImage}>
              <Img {...image} fillContainer showPlaceholder />
            </div>
            {renderCaption}
          </Container>
        )}
        <Container>
          <div className={styles.body}>
            <div className={styles.side}>
              {layout === 'landscape' && renderHeader}
              {layout === 'portrait' && (
                <div>
                  <Img {...image} showPlaceholder />
                  {renderCaption}
                </div>
              )}
            </div>
            <div className={styles.main}>
              {layout === 'portrait' && renderHeader}
              <div className={styles.content}>
                {content && <RichText>{content}</RichText>}
              </div>
              {image && image.url && (
                <Share
                  thumb={image.url}
                  socialNetworks={['facebook', 'twitter', 'linkedin']}
                />
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

BlogPost.defaultProps = {
  imageLayout: 'auto',
}

BlogPost.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.node,
  image: PropTypes.object,
  imageCaption: PropTypes.node,
  imageLayout: PropTypes.string,
}

export default BlogPost
