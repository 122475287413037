// extracted by mini-css-extract-plugin
export var BlogPost = "BlogPost-module--BlogPost--GichJ";
export var aniSpin = "BlogPost-module--ani-spin--xxfmb";
export var body = "BlogPost-module--body--f87qC";
export var caption = "BlogPost-module--caption--RIWKD";
export var content = "BlogPost-module--content--kfwSC";
export var date = "BlogPost-module--date--7-4-v";
export var featureImage = "BlogPost-module--featureImage--WKVDa";
export var header = "BlogPost-module--header--zPNjG";
export var heading = "BlogPost-module--heading--EO+6z";
export var inner = "BlogPost-module--inner--doEK3";
export var landscapeLayout = "BlogPost-module--landscapeLayout--h+FZk";
export var main = "BlogPost-module--main--B3NSh";
export var portraitLayout = "BlogPost-module--portraitLayout--fvbFp";
export var side = "BlogPost-module--side--hW4Qx";